import { Icon } from '@iconify/react';
import { useCallback, useState } from 'react';
import searchFill from '@iconify/icons-eva/search-fill';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import queryString from 'query-string';

import { useTranslation } from 'react-i18next';

import { styled, alpha } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Input, Slide, Button, InputAdornment, ClickAwayListener } from '@mui/material';

import { MIconButton } from '../../components/@material-extend';
import { useLocation, useNavigate, useSearchParams } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';

import { setActiveFilters } from 'src/redux/slices/reservations';

import { useDispatch, useSelector } from 'src/redux/store';

export const validationSchema = Yup.object().shape({
  q: Yup.string().notRequired()
});

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({ theme }) => ({
  top: 0,
  left: -16,
  right: -16,
  zIndex: 99,
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
    left: -40,
    right: -40
  }
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const { activeFilters } = useSelector((state) => state.reservations);

  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      q: searchParams.get('q') || '',
      sort: searchParams.get('sort') || '',
      order: searchParams.get('order') || 'DESC'
    },
    validationSchema,
    onSubmit: async (values) => {
      const { by_state, ...filters } = { ...activeFilters, q: values.q };
      dispatch(setActiveFilters(filters, setSearchParams));

      if (!pathname.includes('reservations/list')) {
        const params = queryString.stringify(filters);
        navigate(`${PATH_DASHBOARD.reservations.list}?${params}`);
      }
    }
  });

  const { values, handleChange, handleSubmit } = formik;
  const resetSearch = useCallback(() => {
    formik.setFieldValue('q', '');
    handleSubmit();
  }, []);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <MIconButton onClick={handleOpen}>
            <Icon icon={searchFill} width={20} height={20} />
          </MIconButton>
        )}

        <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            <form noValidate autoComplete="off" onSubmit={handleSubmit} style={{ width: '100%' }}>
              <Box width="100%" display="flex">
                <Input
                  autoFocus
                  fullWidth
                  disableUnderline
                  placeholder={t('Znajdź rezerwację...')}
                  name="q"
                  value={values.q}
                  onChange={handleChange}
                  startAdornment={
                    <InputAdornment position="start">
                      <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                  }
                  sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
                />
                {values.q && (
                  <Button
                    type="reset"
                    onClick={resetSearch}
                    variant="text"
                    color="inherit"
                    startIcon={<CloseIcon />}
                    sx={{ paddingRight: '16px', paddingLeft: '16px', marginRight: '8px' }}
                  >
                    {t('Wyczyść')}
                  </Button>
                )}
                <Button type="submit" variant="contained">
                  {t('Szukaj')}
                </Button>
              </Box>
            </form>
          </SearchbarStyle>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}
