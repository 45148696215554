import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { API_ROUTES } from 'src/routes/api';

// ----------------------------------------------------------------------

export const MODAL_TYPE = {
  PRICE_LIST: 'PRICE_LIST',
  CREATE_DISCOUNT: 'CREATE_DISCOUNT',
  MARINA: 'MARINA',
  LABELS: 'LABELS',
  GALLERY_CLONE: 'GALLERY_CLONE'
};

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  partnerYachtsLoading: false,
  error: false,
  yachtsList: {
    data: [],
    pagination: {}
  },
  partnerYachts: [],
  filters: {},
  activeFilters: {},
  activeModal: {
    type: null,
    data: null
  },
  searchList: [],
  availableLabels: [],
  selectedLabels: [],
  yacht: null,
  promotions: [],
  reservationsList: {
    data: [],
    pagination: {}
  }
};

const slice = createSlice({
  name: 'yachts',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = false;
    },

    startPartnerYachtsLoading(state) {
      state.partnerYachtsLoading = true;
      state.error = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.partnerYachtsLoading = false;
      state.error = action.payload;
    },

    // GET YACHTS
    getYachtsSuccess(state, action) {
      state.isLoading = false;
      state.yachtsList = {
        data: action.payload.yachts,
        pagination: action.payload.meta.pagination
      };
    },

    getFiltersSuccess(state, action) {
      state.isLoading = false;
      state.filters = action.payload;
    },

    setActiveFilters(state, action) {
      state.activeFilters = action.payload;
    },

    setActiveModal(state, action) {
      state.activeModal = {
        type: action.payload?.type || null,
        data: action.payload?.data || null
      };
    },

    setSearchList(state, action) {
      state.isLoading = false;
      state.searchList = action.payload;
    },

    setPartnerYachtsList(state, action) {
      state.partnerYachtsLoading = false;
      state.partnerYachts = action.payload;
    },

    setLabels(state, action) {
      state.isLoading = false;
      state.availableLabels = action.payload.availableLabels || [];
      state.selectedLabels = action.payload.selectedLabels || [];
    },

    getYachtSuccess(state, action) {
      state.isLoading = false;
      state.yacht = action.payload;
    },

    clearYacht(state) {
      state.yacht = null;
    },

    updateYachtMainImage(state, action) {
      state.yacht = { ...state.yacht, image: action.payload };
      state.isLoading = false;
    },

    updateYachtImagesType(state, action) {
      state.yacht = { ...state.yacht, realImages: action.payload };
    },

    getYachPromotionsSuccess(state, action) {
      state.isLoading = false;
      state.promotions = action.payload;
    },

    getYachtReservationsSuccess(state, action) {
      state.isLoading = false;
      state.reservationsList = {
        data: action.payload.reservations,
        pagination: action.payload.meta.pagination
      };
    },

    reset(state) {
      Object.keys(state).forEach((key) => (state[key] = initialState[key]));
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setActiveModal, clearYacht } = slice.actions;

// ----------------------------------------------------------------------

export function getYachtsList(page = 1, page_size = 10, filters) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());

    const state = getState();

    const activeFilters = filters || state.yachts.activeFilters;

    try {
      const {
        data: { yachts, meta }
      } = await axios.get(API_ROUTES.yachts.list, {
        params: {
          page,
          page_size,
          ...activeFilters
        }
      });
      dispatch(slice.actions.getYachtsSuccess({ yachts, meta }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function refetchYachtsList() {
  return async (dispatch, getState) => {
    const { yachts } = getState();
    const { currentPage, pageSize } = yachts.yachtsList.pagination;

    dispatch(getYachtsList(currentPage, pageSize));
  };
}

export function getYachtsFilters() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const {
        data: { filters }
      } = await axios.get(API_ROUTES.filters);
      dispatch(slice.actions.getFiltersSuccess(filters));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setActiveFilters(activeFilters) {
  return async (dispatch, getState) => {
    const { yachts } = getState();
    const { pageSize } = yachts.yachtsList.pagination;

    dispatch(slice.actions.setActiveFilters(activeFilters));

    dispatch(getYachtsList(1, pageSize, activeFilters));
  };
}

export function getYachtDetails(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const {
        data: { yacht }
      } = await axios.get(API_ROUTES.yachts.by_index(id));
      dispatch(slice.actions.getYachtSuccess(yacht));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getYachtsSearch() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const {
        data: { yachts }
      } = await axios.get(API_ROUTES.yachts.search);
      dispatch(slice.actions.setSearchList(yachts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPartnerYachts(partnerId) {
  return async (dispatch) => {
    dispatch(slice.actions.startPartnerYachtsLoading());

    try {
      const {
        data: { yachts }
      } = await axios.get(API_ROUTES.yachts.partnerYachts(partnerId));
      dispatch(slice.actions.setPartnerYachtsList(yachts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getLabelsList(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const url = id ? API_ROUTES.yachts.labels_by_index(id) : API_ROUTES.yachts.labels;

    try {
      const { data } = await axios.get(url);
      dispatch(slice.actions.setLabels(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateYachtMainImage(image) {
  return async (dispatch) => {
    dispatch(slice.actions.updateYachtMainImage(image));
  };
}

export function updateYachtImageType(isRealImage) {
  return async (dispatch) => {
    dispatch(slice.actions.updateYachtImagesType(isRealImage));
  };
}

export function getYachtPromotions(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const {
        data: { priceCuts }
      } = await axios.get(API_ROUTES.yachts.promotions(id));
      dispatch(slice.actions.getYachPromotionsSuccess(priceCuts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getYachtReservations(page = 1, page_size = 10, id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const {
        data: { reservations, meta }
      } = await axios.get(API_ROUTES.yachts.reservations(id), {
        params: {
          page,
          page_size
        }
      });
      dispatch(slice.actions.getYachtReservationsSuccess({ reservations, meta }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function reset() {
  return async (dispatch) => {
    dispatch(slice.actions.reset());
  };
}
